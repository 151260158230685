<template>
  <ag-grid-table ref="gridListExistencias" :configUrl="config" :dataUrl="data" :editUrl="edit" rowSelection="single">
    <!-- <template v-slot:actions>
      <div>
        <div class="heading-elements">
          <ul class="list-inline text-right mb-1">
            <li>
              <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-1 size="20" />
            </li>
          </ul>
        </div>
      </div>
      <div>
			<b-sidebar id="sidebar-1" title="Stock existencias" shadow bg-variant="dark" text-variant="light"
				width="450px" right backdrop>
				<div class="px-2 py-2">
					<p>
						<strong>Stock Existencias:</strong> En esta secci&oacute;n usted podr&aacute; subir valores uno a uno de sus existencias donde deber&aacute; llenar cada una de las celdas solicitadas.
					</p>
					<p><strong>Hist&oacute;rico stock existencias:</strong> En la tabla usted visualizar&aacute; los datos que ha ido cargando uno a uno. En caso de alg&uacute;n error puede dar doble click sobre el valor para modificaciones o seleccionar el bot&oacute;n eliminar para quitar el registro.</p>
					<p>Puede filtrar sus datos donde aparece el &iacute;cono de filtros.</p>
				</div>
			</b-sidebar>
		</div>
    </template> -->
  </ag-grid-table>
</template>
<script>
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EliminarActions from "./actions/EliminarActions.vue";
import RepeatStatistics from "@/views/clients/molymet/components/RepeatStatistics";
import NumberCellEditor from "@/views/brunacomponents/ag-grid-table/cell-editors/NumberCellEditor"
import {
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";

export default {
  components: {
    AgGridTable,
    BRow,
    BCol,
    BButton,
    BrunaForm,
    ToastificationContent,
    EliminarActions: EliminarActions,
    RepeatStatistics,
    NumberCellEditor,

  },
  data() {
    return {
      config: useApiServices.historicoStockExistenciasConfig,
      data: useApiServices.historicoStockExistenciasData,
      edit: useApiServices.HistoricoStockExistenciasEdit,
      itemsData: [],
    };
  },

  methods: {
    refreshRows() {
      this.$refs['gridListExistencias'].refreshRows();
    }
  }

};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}

.mt-3 {
  border: 1px solid green !important;
  background-color: green !important;
  color: white !important;
}

.mt-3:hover {

  transition: 0.3s ease-in !important;
  border: 1px solid green !important;
  background-color: transparent !important;
  color: green !important;
  color: white;
}

.mt-2 {
  border: 1px solid red !important;
  background-color: red !important;
  color: white !important;
}

.mt-2:hover {

  transition: 0.5s ease-in !important;
  border: 1px solid red !important;
  background-color: transparent !important;
  color: red !important;
}
</style>