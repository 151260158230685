<template>
  <section id="molymet-ingreso-existencias">

    <b-row>
      <b-col>
        <BrunaForm ref="existenciaRef" :fields="this.existencia.fields" :card="this.existencia.card" :helper="true">
          <template v-slot:footer>
            <b-button variant="primary" class="mt-4 float-right" @click="addNewExistencia">Agregar</b-button>
          </template>
        </BrunaForm>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <HistoricoStockExistencias ref="tableExistencias"></HistoricoStockExistencias>
      </b-col>
    </b-row>

  </section>
</template>
<script>
import useApiServices from '@/services/useApiServices.js';
import {
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import BrunaForm from "@/views/brunacomponents/BrunaForm2.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import NumberCellEditor from "@/views/brunacomponents/ag-grid-table/cell-editors/NumberCellEditor"
import HistoricoStockExistencias from "./HistoricoStockExistencias.vue";


export default {
  components: {
    BRow,
    BCol,
    BButton,
    BrunaForm,
    AgGridTable,
    ToastificationContent,
    NumberCellEditor,
    HistoricoStockExistencias,

  },
  created() {
  },
  data() {
    return {

      existencia: {
        card: {
          title: "Stock Existencias",
          subtitle: "",
          tooltip: "",
          sidebarContent: {
            title: 'Stock existencias',
            body: "<p><strong>Stock Existencias:</strong> En esta secci&oacute;n usted podr&aacute; subir valores uno a uno de sus existencias donde deber&aacute; llenar cada una de las celdas solicitadas.</p><p><strong>Hist&oacute;rico stock existencias:</strong> En la tabla usted visualizar&aacute; los datos que ha ido cargando uno a uno. En caso de alg&uacute;n error puede dar doble click sobre el valor para modificaciones o seleccionar el bot&oacute;n eliminar para quitar el registro.</p><p>Puede filtrar sus datos donde aparece el &iacute;cono de filtros.</p>"
          }
        },
        fields: [
          {
            label: "Fecha",
            type: "datepicker",
            id: 'fecha',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null,
            min:'2020-08-01',
            max:'2021-12-31',
          },
          {
            label: "Planta",
            type: "select",
            id: 'planta',
            rules: 'required',
            options: [
              { value: 'Oxidación', text: "Oxidación" }
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Código procedencia",
            type: "select",
            id: 'codigo-procedencia',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: 'ANT', text: "ANT" },
              { value: 'CJ', text: "CJ" },
              { value: 'COL', text: "COL" },
              { value: 'DP', text: "DP" },
              { value: 'MCO', text: "MCO" },
              { value: 'MSA', text: "MSA" },
              { value: 'MTE', text: "MTE" },
            ],
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Lote",
            type: "input",
            id: 'lote',
            rules: 'required',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null,
            show: true
          },
          {
            label: "Envases",
            type: "input",
            id: 'envases',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null,
            show: true
          },
          {
            label: "Cantidad (Kg)",
            type: "input",
            id: 'cantidadkg',
            rules: 'required|numeric2',
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
        ]
      },

    };
  },
  methods: {

    addNewExistencia(event) {
      let data = this.existencia.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      //let _data = this.existencia.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})
      console.log(data);
      Promise.all([
        this.$refs.existenciaRef.validate(),
      ]).then(([existenciaValid]) => {
        if (existenciaValid) {
          // console.log(_data);
          // return 
          useApiServices.postStockExistenciaCreate(data)
            .then((response) => {
              console.log(response);

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Carga Exitosa`,
                  icon: "UploadIcon",
                  variant: "success",
                  html: `Se ha guardado el nuevo registro exitosamente.`,
                },
              });
              console.log("refrescando la tabala ahora...");
              this.$refs.tableExistencias.refreshRows();


            })
            .catch((error) => {
              let message = error.response.data.message;

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Ocurrio un Error`,
                  icon: "UploadIcon",
                  variant: "danger",
                  html: message,
                },
              });

            });
        }
      });
    },
  },
  mounted() {

  },

};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}
</style>